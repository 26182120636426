// import Home from "./component/home";
// import Services from "./component/services";
// import { BrowserRouter as Router, Route } from "react-router-dom";

// function App() {
//   return (
//     <div className="App">
//       <Home path="/" />
//       <Services path="/services" />
//     </div>
//   );
// }
import "./App.css";
import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./component/home";

const About = lazy(() => import("./component/about"));
const Services = lazy(() => import("./component/services"));
const Contact = lazy(() => import("./component/contact"));

const CloudMigration = lazy(() =>
  import("./component/services/it-service/Cloud Migration")
);
const Ictsolutions = lazy(() =>
  import("./component/services/it-service/ICT Solutions")
);
const Itconsultancy = lazy(() =>
  import("./component/services/it-service/IT Consultancy")
);
const Itinfrastructure = lazy(() =>
  import("./component/services/it-service/IT Infrastructure")
);
const Itoutsourcing = lazy(() =>
  import("./component/services/it-service/IT Outsourcing")
);
const Itsupport = lazy(() =>
  import("./component/services/it-service/IT Support")
);
const Newofficeitsetup = lazy(() =>
  import("./component/services/it-service/New Office IT Setup")
);
const Itservice = lazy(() => import("./component/services/it-service/it-amc"));
const Itrelocation = lazy(() =>
  import("./component/services/it-service/it-relocation")
);

const Bpooutsourcing = lazy(() =>
  import("./component/services/outsourcing/BPO Outsourcing")
);

const Databackupdisasterrecoveryservices = lazy(() =>
  import(
    "./component/services/IT Infrastructure/Data Backup & Disaster Recovery Services"
  )
);
const Firewallinstallation = lazy(() =>
  import("./component/services/IT Infrastructure/Firewall Installation")
);
const Ithardwareservices = lazy(() =>
  import("./component/services/IT Infrastructure/IT Hardware Services")
);
// const Newofficeitsetup = lazy(() => import("./component/services/IT Infrastructure/New Office IT Setup"));
const Office365services = lazy(() =>
  import("./component/services/IT Infrastructure/Office 365 Services")
);
const Officetelephonesystem = lazy(() =>
  import("./component/services/IT Infrastructure/Office Telephone System")
);
const Servervirtualizationservices = lazy(() =>
  import(
    "./component/services/IT Infrastructure/Server Virtualization Services"
  )
);
const Structurecabling = lazy(() =>
  import("./component/services/IT Infrastructure/Structure Cabling")
);
const Vpnsolutions = lazy(() =>
  import("./component/services/IT Infrastructure/VPN Solutions")
);

const Accesscontroltimeattendance = lazy(() =>
  import("./component/services/ELV & Security/Access Control & Time Attendance")
);
const Cctvinstallationdubai = lazy(() =>
  import("./component/services/ELV & Security/CCTV Installation Dubai")
);
const Siraapprovedcctvinstallation = lazy(() =>
  import("./component/services/ELV & Security/SIRA Approved CCTV Installation")
);
const Telephonesolutions = lazy(() =>
  import("./component/services/ELV & Security/Telephone Solutions")
);

const Computer = lazy(() =>
  import("./component/services/computer/computer")
);

function App() {
  return (
    <>
      <Router>
        <Suspense fallback={<h1>loading.....</h1>}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/cloudmigration" element={<CloudMigration />} />
            <Route path="/ictsolutions" element={<Ictsolutions />} />
            <Route path="/itconsultancy" element={<Itconsultancy />} />
            <Route path="/itinfrastructure" element={<Itinfrastructure />} />
            <Route path="/itoutsourcing" element={<Itoutsourcing />} />
            <Route path="/itsupport" element={<Itsupport />} />
            <Route path="/newofficeitsetup" element={<Newofficeitsetup />} />
            <Route path="/itamc" element={<Itservice />} />
            <Route path="/itrelocation" element={<Itrelocation />} />

            <Route path="/bpooutsourcing" element={<Bpooutsourcing />} />

            <Route
              path="/databackupdisasterrecoveryservices"
              element={<Databackupdisasterrecoveryservices />}
            />
            <Route
              path="/firewallinstallation"
              element={<Firewallinstallation />}
            />
            <Route
              path="/ithardwareservices"
              element={<Ithardwareservices />}
            />
            <Route path="/office365services" element={<Office365services />} />
            <Route
              path="/officetelephonesystem"
              element={<Officetelephonesystem />}
            />
            <Route
              path="/servervirtualizationservices"
              element={<Servervirtualizationservices />}
            />
            <Route path="/Structurecabling" element={<Structurecabling />} />
            <Route path="/Vpnsolutions" element={<Vpnsolutions />} />

            <Route
              path="/accesscontroltimeattendance"
              element={<Accesscontroltimeattendance />}
            />
            <Route
              path="/cctvinstallationdubai"
              element={<Cctvinstallationdubai />}
            />
            <Route
              path="/siraapprovedcctvinstallation"
              element={<Siraapprovedcctvinstallation />}
            />
            <Route
              path="/telephonesolutions"
              element={<Telephonesolutions />}
            />
            
            <Route
              path="/computer"
              element={<Computer />}
            />
            {/* <Route path="*" element={<Navigate to="/" />} /> */}
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
